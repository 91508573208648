import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// PAges
import { Error404, Home, About, Energy, System, Contact } from '~/pages';

const SystemRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/sobre-nos" element={<About />} />
                <Route path="/energia" element={<Energy />} />
                <Route path="/sistema" element={<System />} />
                <Route path="/contato" element={<Contact />} />
                <Route path="/404" element={<Error404/>} />

                {/* Error */}
                <Route path="/*" element={<Navigate to="/404" replace />} />
            </Routes>
        </BrowserRouter>
    )
}

export default SystemRoutes;