import React from 'react';

type boxIcon = {
    icon: JSX.Element,
    title: string,
    label: string,
}

function boxIcon (props: boxIcon) {
  return <div className='box-icon'>
    <div>
      {props.icon}
      <h6>{props.title}</h6>
    </div>
    <p>{props.label}</p>
</div>
}

export default boxIcon;