import React from 'react';
import { Link } from 'react-router-dom';
import {FaLinkedinIn, FaFacebookF, FaInstagram, FaTwitter} from 'react-icons/fa'
import './styles.css';

// Images
import logo from '~/assets/images/logo/logo.svg';

const Footer = () => {
    return (
        <footer>
            <div className='container'>
                <div className="infos">
                    <div className='logo'>
                        <img src={logo} alt="Energizou"/>
                    </div>

                    <div className='links'>
                        <Link to='/sobre-nos' className='title'>Sobre</Link>
                        <Link to='/energia' className='page-energy'>Energia</Link>
                        <Link to='/sistema' className='page-system'>Sistema</Link>
                        <Link to='/contato' className='page-contact'>Contato</Link>
                        <a target='_blank' rel="noreferrer" href='https://blog.energizou.com.br/'>  Blog  </a>
                    </div>

                    <div className='social'>
                        <a target='_blank' rel="noreferrer" href='https://www.instagram.com/energizou_oficial/' className='social_icon'>  <FaInstagram />  </a>
                        <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/company/energizou/' className='social_icon'>  <FaLinkedinIn/>  </a>
                        <a target='_blank' rel="noreferrer" href='https://www.facebook.com/energizou' className='social_icon'>  <FaFacebookF/>  </a>
                        <a target='_blank' rel="noreferrer" href='https://twitter.com/EnergizouSup' className='social_icon'>  <FaTwitter/>  </a>
                    </div>
                </div>

                <div className="copyright">
                    <p>Rua George Ohm, 230, Torre A, 8º andar - Cidade Monções, São Paulo/SP - 04576-020</p>
                    <p>© 2024 Energizou. Todos os direitos reservados.</p>
                </div>
            </div>
        </footer>

    );
}

export default Footer;