import React from 'react';
import ButtonNormal from '~/components/Buttons/ButtonNormal';
import { BiBarChart } from "react-icons/bi";

type boxCases = {
    image: string,
    alt  : string
    title: string,
    label: string,
    link : string,
}

function BoxCases (props: boxCases) {
  return <div className='box-cases'>
    <div>
        <img src={props.image} alt={props.alt}/>
        <h5>{props.title}</h5>
        <p>{props.label}</p>
    </div>
    <ButtonNormal text={'Ver economia'} icon={<BiBarChart/>} link={props.link} external={true}/>
</div>
}

export default BoxCases;