import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';


type Props = {
    text: string,
    light?: boolean,
    link : string,
    external?: boolean,
    className?: string;
}

const ButtonPrimary: React.FC<any> = (props:Props) => {
    let style = props.light === true ? 'light' : ''

    if (props.external === true){
        return <a className={`btn-primary ${style} ${props.className}`} target='_blank' rel="noreferrer" href={props.link}>
            {props.text}
        </a>
    } else {
        return <Link className={`btn-primary ${style} ${props.className}`}to={props.link}>
            {props.text}
        </Link>
    }

}

export default ButtonPrimary;