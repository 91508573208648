import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';

type Props = {
    text: string,
    link : string,
    external?: boolean,
    className?: string;
}

const ButtonSecundary: React.FC<any> = (props:Props) => {
    if (props.external === true){
        return <a className={`btn-secundary ${props.className}`} target='_blank' rel="noreferrer" href={props.link}>
            {props.text}
        </a>
    } else {
        return <Link to={props.link} className={`btn-secundary ${props.className}`}>
            {props.text}
        </Link>
    }
}

export default  ButtonSecundary;