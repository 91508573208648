import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { BiSolidChevronRight } from "react-icons/bi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.css';

// Components
import Site from '~/components/System/Layout';
import Banner from './Components/Banner';
import ConditionsBox from './Components/ConditionsBox';

// Images
import { piggyBank, eolica, invoice, fotoSystem, img4, img5, img6, img7, img8, img9, img11 } from './images';


const Home: React.FC<any> = () => {
    const [collapseOpen, setCollapseOpen] = useState(0);

    const settings = {
        cssEase: "linear",
        autoplaySpeed: 100,
        autoplay: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: window.innerWidth > 430 ? 8:10,
        speed: 6000
    };

    return <Site className='home' title='Energizou - Energia com possibilidades e autonomia' pageTitle='Energizou - Energia com possibilidades e autonomia'>
        <Banner />

        <div className="carousel">
                <h2>Empresas que confiam em nós</h2>
            <div className="slider-container">
                <Slider {...settings}>
                    <div className='imagem-desaturada' >
                        <img src={img4} alt="imagem" />
                    </div>
                    <div className='imagem-desaturada'>
                        <img src={img5} alt="imagem" />
                    </div>
                    <div className='imagem-desaturada'>
                        <img src={img6} alt="imagem" />
                    </div>
                    <div className='imagem-desaturada'>
                        <img src={img7} alt="imagem" />
                    </div>
                    <div className='imagem-desaturada'>
                        <img src={img8} alt="imagem" />
                    </div>
                    <div className='imagem-desaturada'>
                        <img src={img9} alt="imagem" />
                    </div>
                    <div className='imagem-desaturada' >
                        <img src={img4} alt="imagem" />
                    </div>
                    <div className='imagem-desaturada'>
                        <img src={img11} alt="imagem" />
                    </div>
                    <div className='imagem-desaturada'>
                        <img src={img7} alt="imagem" />
                    </div>
                    <div className='imagem-desaturada'>
                        <img src={img6} alt="imagem" />
                    </div>
                </Slider>
            </div>
        </div>

        <div className="freeMarket">
            <div className='content'>
                <div className="textfreeMarket">
                    <div className='title'>
                        <p>LIBERDADE PARA ESCOLHER</p>
                        <h2>O Mercado Livre de Energia permite que você escolha de quem vai comprar</h2>
                    </div>

                    <div className='infoFreeMarket'>
                        <p>A Energizou ajuda sua empresa a economizar dinheiro na conta de eletricidade, oferecendo energia mais barata que a da sua distribuidora, por meio do Mercado Livre de Energia. A concorrência do livre mercado, além de oferecer preços mais baixos, oferece a oportunidade de comprar energia de fonte renovável.</p>
                        <ul>
                            <li>
                                <img src={piggyBank} alt="Icone Autonomia" />
                                 Gaste menos na sua conta mensal de energia.
                            </li>
                            <li>
                                <img src={eolica} alt="Icone Ambição" />
                                Escolha energia de fonte renovável.
                            </li>
                            <li>
                                <img src={invoice} alt="Icone Criatividade" />
                                Tenha uma conta previsível, sem bandeiras ou taxas escondidas.
                            </li>
                        </ul>
                    </div>

                </div>
                <div className='video'>
                    <iframe
                        className='rounded-iframe'
                        src="https://www.youtube.com/embed/poJffXRi3dk?si=xCW_Fp0t4LpvZRbt"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
                <div className='infoLink'>
                    <button><Link to="/contato" className='page-contact'>ENTRE EM CONTATO</Link></button>
                    <div>
                        <Link to="/energia" className='page-energy'>Saiba mais</Link>
                        <i><BiSolidChevronRight size={15} color='#003063' /></i>
                    </div>
                </div>
            </div>
        </div>

        <div className='system'>
            <div className='content'>
                <div className='infoSystem'>
                    <div className='divImg'>
                           <img className='img' src={fotoSystem} alt="" />
                    </div>
                    <div className='textSystem'>
                        <div className='box'>
                            <div>
                                <div className='subtitulo'>Nosso sistema avalia sua conta e identifica oportunidades</div>
                                <p>Nosso aplicativo web gratuito analisa automaticamente sua conta de energia e fornece informações valiosas para ajudá-lo a economizar dinheiro. Diga adeus aos altos custos com eletricidade!</p>
                            </div>

                            <div className='porcentagens'>
                                <div>
                                    <h2>15 a 35%</h2>
                                    <p>De <b>economia</b> no seu custo mensal de energia. Nosso sistema gera recomendações personalizadas para otimizar seus gastos com eletricidade.</p>
                                </div>

                                <div>
                                    <h2>R$ 10mil +</h2>
                                    <p>Se sua empresa gasta mais de R$ 10 mil reais por mês e está conectada na rede de alta tensão, já é possível escolher de onde você compra a sua energia.</p>
                                </div>
                            </div>

                            <div className='button'>
                                <p><b>Descubra se nossa plataforma é a solução perfeita para você! </b>Teste a eficácia enviando sua fatura agora mesmo.</p>
                                <button>
                                    <a target="_blank" rel="noreferrer" className='page-superapp' href="https://app.energizou.com.br/criar-conta">TESTAR AGORA</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <div className='questions'>
            <article className='content'>
                <div className='context'>
                    <div className='commonQuestion'>
                        <div>
                            <h2>Perguntas frequentes</h2>
                            <p>Saiba mais sobre como economizar com a sua conta de eletricidade, como funciona o Mercado Livre de Energia ou como funciona o serviço da Energizou.</p>
                            <button><Link to="/contato" className='page-contact'>ENTRE EM CONTATO</Link></button>
                        </div>
                    </div>

                    <div className='question'>
                        <ConditionsBox
                            index={1}
                            collapseOpen={collapseOpen}
                            setCollapseOpen={setCollapseOpen}
                            title="Quais são as vantagens do Mercado Livre de Energia?"
                            description=" O consumidor passa a ser livre para negociar condições comerciais diretamente com diversos
                            fornecedores de energia. Isso resulta em uma possibilidade de redução de ate 35% nos custos com
                            energia elétrica."
                        />

                        <ConditionsBox
                            index={2}
                            collapseOpen={collapseOpen}
                            setCollapseOpen={setCollapseOpen}
                            title="Para quem é o Mercado Livre de Energia?"
                            description=" O Mercado Livre de Energia é uma modalidade de contratação de energia disponível para todos os
                            consumidores que fazem parte do Grupo A de tensão de fornecimento (média e alta tensão)."
                        />

                        <ConditionsBox
                            index={4}
                            collapseOpen={collapseOpen}
                            setCollapseOpen={setCollapseOpen}
                            title="Qual a diferença entre o Mercado Livre de Energia e o modelo tradicional?"
                            description="No modelo tradicional, também chamado de Mercado Cativo de Energia, o consumidor paga a tarifa regulada pela Agência Nacional de Energia Elétrica, ANEEL à sua distribuidora.
                            Já no Mercado Livre de Energia, o consumidor é livre para escolher seu fornecedor de energia e
                            negociar livremente condicões comerciais."
                        />

                        <ConditionsBox
                            index={5}
                            collapseOpen={collapseOpen}
                            setCollapseOpen={setCollapseOpen}
                            title="Para quem eu pago a conta de energia?"
                            description="No Mercado Cativo de Energia, o consumidor paga em uma conta de energia para a distribuidora o
                            custo da energia consumida e o custo pela distribuição.
                            No Mercado Livre de Energia o consumidor passa a ter duas faturas: uma para a distribuidora, pelos
                            servicos de distribuição de energia. outra para o fornecedor de energia, pela quantidade de energia
                            consumida."
                        />

                        <ConditionsBox
                            index={6}
                            collapseOpen={collapseOpen}
                            setCollapseOpen={setCollapseOpen}
                            title="Existe risco de falta de fornecimento no Mercado Livre de Energia?"
                            description="A distribuidora continua sendo encarregada pela entrega da energia ate o seu estabelecimento. Dessa
                            forma, o risco é o mesmo do Mercado Cativo."
                        />

                        <ConditionsBox
                            index={7}
                            collapseOpen={collapseOpen}
                            setCollapseOpen={setCollapseOpen}
                            title="Como posso aderir ao Mercado Livre de Energia?"
                            description="Para entrar no Mercado Livre de Energia, é necessário buscar uma Comercializadora de energia ou
                            fornecedor autorizado para auxilior na contratação de energia e prestação dos serviços necessários.
                            A Energizou oferece pacotes de fornecimento de energia e auxílio em todas as etapas operacionais
                            para garantir que o projeto do Mercado Livre de Energia seja um grande sucesso.
                            Solicite agora uma proposta em energizou.com.br/contato"
                        />
                    </div>
                </div>
            </article>
        </div>
    </Site>
}

export default Home;