import React from 'react';
import Routes from './routers';
import { MenuProvider } from '~/contexts';

declare global {
    interface Window { RDStationForms: any; }
}
const  App: React.FC = () => (
    <MenuProvider>
        <Routes />
    </MenuProvider>
)

export default App;
