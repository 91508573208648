import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import Anim from './animations';
import { MenuContext } from '~/contexts';
import './styles.css';

// Images
import load from './images/load.svg';
import mobileBkg from './images/mobile.jpg';
import palms from './images/palm.gif';
import palms_loop from './images/palm_loop.gif';
import palms_png from './images/palms.png';
import palms_loop_png from './images/palms_loop.png';

import house1_default from './images/house_1.png';
import house1_500 from './images/house_1-p-500.png';
import house1_800 from './images/house_1-p-800.png';
import house1_1080 from './images/house_1-p-1080.png';
import house1_1600 from './images/house_1-p-1600.png';
import house1_2000 from './images/house_1-p-2000.png';

import house2_default from './images/house_2-1.png';
import house2_500 from './images/house_2-1-p-500.png';
import house2_800 from './images/house_2-1-p-800.png';
import house2_1080 from './images/house_2-1-p-1080.png';
import house2_1600 from './images/house_2-1-p-1600.png';
import house2_2000 from './images/house_2-1-p-2000.png';

import house3_default from './images/house_3.png';
import house3_500 from './images/house_3-p-500.png';
import house3_800 from './images/house_3-p-800.png';
import house3_1080 from './images/house_3-p-1080.png';
import house3_1600 from './images/house_3-p-1600.png';
import house3_2000 from './images/house_3-p-2000.png';

import house4_default from './images/house_1_2_v2.png';
import house4_500 from './images/house_1_2_v2-p-500.png';
import house4_800 from './images/house_1_2_v2-p-800.png';
import house4_1080 from './images/house_1_2_v2-p-1080.png';
import house4_1600 from './images/house_1_2_v2-p-1600.png';

import house5_default from './images/house_4.png';
import house5_500 from './images/house_4-p-500.png';
import house5_800 from './images/house_4-p-800.png';
import house5_1080 from './images/house_4-p-1080.png';
import house5_1600 from './images/house_4-p-1600.png';
import house5_2000 from './images/house_4-p-2000.png';

import shops1_default from './images/shops_1_version_3_compressed.png';
import shops1_500 from './images/shops_1_version_3_compressed-p-500.png';
import shops1_800 from './images/shops_1_version_3_compressed-p-800.png';

import shops2_default from './images/shops_2_light_version_3_compressed.png';
import shops2_800 from './images/shops_2_light_version_3_compressed-p-800.png';
import shops2_1080 from './images/shops_2_light_version_3_compressed-p-1080.png';

// Json
import booat1 from './images/docs/booat_1.json';
import booat2 from './images/docs/booat_2.json';
import sea from './images/docs/sea.json';
import house1 from './images/docs/house_1.json';
import house2 from './images/docs/house_2.json';
import wnd from './images/docs/wnd.json';

const Banner: React.FC = () => {
    const { mobile } = useContext(MenuContext);

    function browsersOff() {
        let isSafari       = (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Edg") > -1 ? true : false);
        let isSafariMobile = (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.indexOf('AppleWebKit') > -1 ? true : false);
        let isIEOlder      = (navigator.userAgent.indexOf("MSI") > -1);

        return isSafari || isSafariMobile || isIEOlder ? true : false;
    }

    useEffect(() => {
        Anim.init(browsersOff())
    }, [])

    return <div id='banner'>
        {
            mobile ? (
                <div className="mobile-container">
                    <img src={mobileBkg} alt="" />
                </div>
            ) : (
                <>
                    <div className="preloader">
                        <img src={load} alt="Carregando animação" />
                    </div>

                    <div className="animation-container">
                        <div className="palmsBox">
                            {
                                browsersOff() ? (<>
                                    <img src={palms_png} alt="" className="safari palm lazyload" />
                                    <img src={palms_loop_png} alt="" className="safari palms_loop lazyload" />
                                </>) : (<>
                                    <img src={palms} alt="" className="gen palm lazyload" />
                                    <img src={palms_loop} alt="" className="gen palms_loop lazyload" />
                                </>)
                            }
                        </div>

                        <Lottie
                            animationData={booat1}
                            loop play
                            className="boat_left"
                        />

                        <Lottie
                            animationData={booat2}
                            loop play
                            className="boat_right"
                        />

                        <Lottie
                            animationData={sea}
                            loop play
                            className="water"
                        />

                        <div className="shops_front">
                            <img src={house1_default}
                                srcSet={`
                                    ${house1_500} 500w,
                                    ${house1_800} 800w,
                                    ${house1_1080} 1080w,
                                    ${house1_1600} 1600w,
                                    ${house1_2000} 2000w,
                                    ${house1_default} 2667w
                                `}
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 600px, (max-width: 991px) 950px, 100vw"
                                alt=""
                                className="house_1 lazyload"
                            />

                            <img src={house2_default}
                                srcSet={`
                                    ${house2_500} 500w,
                                    ${house2_800} 800w,
                                    ${house2_1080} 1080w,
                                    ${house2_1600} 1600w,
                                    ${house2_2000} 2000w,
                                    ${house2_default} 2667w
                                `}
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 600px, (max-width: 991px) 950px, 100vw"
                                alt=""
                                className="house_2 lazyload"
                            />

                            <img src={house3_default}
                                srcSet={`
                                    ${house3_500} 500w,
                                    ${house3_800} 800w,
                                    ${house3_1080} 1080w,
                                    ${house3_1600} 1600w,
                                    ${house3_2000} 2000w,
                                    ${house3_default} 2667w
                                `}
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 600px, (max-width: 991px) 950px, 100vw"
                                alt=""
                                className="house_3 lazyload"
                            />

                            <img src={shops1_default}
                                srcSet={`
                                    ${shops1_500} 500w,
                                    ${shops1_800} 800w,
                                    ${shops1_default} 2667w
                                `}
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 600px, (max-width: 991px) 950px, 100vw"
                                alt=""
                                className="shops_1_lights lazyload"
                            />
                        </div>

                        <div className="shops_back">
                            <img src={house4_default}
                                srcSet={`
                                    ${house4_500} 500w,
                                    ${house4_800} 800w,
                                    ${house4_1080} 1080w,
                                    ${house4_1600} 1600w,
                                    ${house4_default} 2667w
                                `}
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 600px, (max-width: 991px) 950px, 100vw"
                                alt=""
                                className="house_1 lazyload"
                            />

                            <img src={house5_default}
                                srcSet={`
                                    ${house5_500} 500w,
                                    ${house5_800} 800w,
                                    ${house5_1080} 1080w,
                                    ${house5_1600} 1600w,
                                    ${house5_2000} 2000w,
                                    ${house5_default} 2667w
                                `}
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 600px, (max-width: 991px) 950px, 100vw"
                                alt=""
                                className="house_2 lazyload"
                            />

                            <img src={shops2_default}
                                srcSet={`
                                    ${shops2_800} 800w,
                                    ${shops2_1080} 1080w,
                                    ${shops2_default} 2667w
                                `}
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 600px, (max-width: 991px) 950px, 100vw"
                                alt=""
                                className="shops_2_light lazyload"
                            />
                        </div>

                        <div className="background">
                            <Lottie
                                animationData={house2}
                                loop play
                                className="windows_2"
                            />

                            <Lottie
                                animationData={house1}
                                loop play
                                className="windows_1"
                            />

                            <Lottie
                                animationData={wnd}
                                loop play
                                className="shops_3"
                            />
                        </div>
                    </div>
                </>
            )
        }

        <div className="infos content">
            <h1>Economize até 35% na conta de luz da sua empresa</h1>
            <p>Energizou ajuda a sua empresa a reduzir seus custos com eletricidade analisando suas contas de energia e encontrando oportunidades de economia.</p>

            <div className="btns">
                <a target="_blank" rel="noreferrer" href="https://app.energizou.com.br" className="analysis page-superapp">
                    Simular Economia
                </a>

                <Link to='/contato' className="buy page-contact">
                    Entrar em Contato
                </Link>
            </div>
        </div>
    </div>
}

export default Banner;