import React, { useState } from "react";
import CarouselSimple from "react-simply-carousel";
import image1 from '~/assets/images/energy/Energia_Previsão.png'
import image2 from '~/assets/images/energy/Energia_Relatório.png'
import image3 from '~/assets/images/energy/Energia_Faturas.png'
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import './style.css'


function Carousel () {
  const [activeSlide, setActiveSlide] = useState(0);

  return <CarouselSimple
  containerProps={{
    id:'carousel',
  }}
  preventScrollOnSwipe
  swipeTreshold={60}
  activeSlideIndex={activeSlide}
  onRequestChange={setActiveSlide}
  forwardBtnProps={{
    id: 'btn-right-carousel',
    children: <IoIosArrowRoundForward/>,
  }}
  backwardBtnProps={{
    id: 'btn-left-carousel',
    children: <IoIosArrowRoundBack/>,
  }}
  dotsNav={{
    containerProps:{
      className:'container-dots'
    },
    show: true,
    itemBtnProps: {
      className: 'dots-nav'
    },
    activeItemBtnProps: {
      className: 'dots-nav-selected'
    }
  }}
  itemsToShow={2}
  speed={400}
//   centerMode
>
  <div className="item-carousel">
    <img src={image1} alt='Tela do superapp 1'></img>
    <h4>Previsão de custo e Projeção de consumo</h4>
    <p>Obtenha detalhes dos custos de energia previstos para uma gestão financeira precisa e ajuste seu consumo mensal antecipadamente, garantindo uma gestão mais eficiente.</p>
  </div>
  <div className="item-carousel">
    <img src={image2} alt='Tela do superapp 2'></img>
    <h4>Relatório de economia</h4>
    <p>Gere relatórios detalhados para compreender suas economias mensais, otimizando o consumo e maximizando benefícios financeiros.</p>
  </div>
  <div className="item-carousel">
    <img src={image3} alt='Tela do superapp 3'></img>
    <h4>Faturas de energia</h4>
    <p>Faça upload das faturas de energia da sua distribuidora para gerar relatórios e dados de economia</p>

  </div>
</CarouselSimple>
}

export default Carousel;


