import { Link } from 'react-router-dom';
import './styles.css';

// Components
import Links from './links'

// Images
import logo from '~/assets/images/logo/logo.svg';

const Menu = () => {
    return (
        <>
            <nav className="menu">
                <div className="container">
                    <Link to='/' className="logo">
                        <img src={logo} alt="Energizou"/>
                    </Link>

                    { <Links /> }
                </div>
            </nav>
        </>
    );
}

export default Menu;
