import React from 'react';
import { BiPhone, BiEnvelope, BiMap, BiChevronRight } from "react-icons/bi"
import './styles.css';

// Components
import Site from '~/components/System/Layout';
import BoxIcon from './Components/boxIcon';

const Contact: React.FC<any> = () => {
    const map   = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.784600736394!2d-46.695971524668266!3d-23.612056778765005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5717c0cacdd3%3A0xcf74f73482375a59!2sEnergizou%20-%20Comercializadora%20do%20Mercado%20Livre%20de%20Energia!5e0!3m2!1spt-BR!2sbr!4v1713296368816!5m2!1spt-BR!2sbr";
    const route = "https://www.google.com/maps/dir//Energizou+-+Comercializadora+do+Mercado+Livre+de+Energia+Rua+George+Ohm,+230+-+Cj.+81A+-+Cidade+Mon%C3%A7%C3%B5es+S%C3%A3o+Paulo+-+SP+04576-020/@-23.6120568,-46.6933966,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x94ce5717c0cacdd3:0xcf74f73482375a59";

    return <Site className='contact' title='Contato - Energizou' pageTitle='Contato - Energizou'>
       <div className="contact-us" >
            <div className="content">
                <header>
                    <h1>Fale conosco</h1>
                    <p>Tem uma pergunta ou precisa de ajuda? Entre em contato conosco!</p>
                </header>
                <body>
                    <div className="items">
                        <BoxIcon
                            icon={<BiEnvelope/>}
                            title={'Email'}
                            label={'contato@energizou.com.br'}
                        />

                        <BoxIcon
                            icon={<BiPhone/>}
                            title={'Telefone'}
                            label={'+55 (11) 5507-5528'}
                        />

                        <BoxIcon
                            icon={<BiMap/>}
                            title={'Escritório'}
                            label={'Rua George Ohm, 230, Torre A, 8º andar - Cidade Monções, São Paulo/SP - 04576-020'}
                        />

                        <a target="_blank" rel="noreferrer" href={route}>
                            <p>Como chegar </p>
                            <BiChevronRight />
                        </a>
                    </div>

                    <iframe
                        className="map"
                        title='mapa'
                        src={map}
                        style={{border:'0'}}
                        loading="lazy"
                    />
                </body>
            </div>
        </div>
    </Site>
}

export default Contact;