import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { MenuContext } from '~/contexts';
import './styles.css';

// Components
import Menu from '~/components/System/Menu';
import MenuMobile from '~/components/System/Menu/mobile';
import Links from '../Menu/links';
import Footer from '~/components/System/Footer';

interface Props {
    pageTitle: string;
    title: string;
    subtitle?: string;
    className?: string;
    children: React.ReactNode ;
}

const Site: React.FC<Props> = (props) => {
    const { setLocation, menuOpen, mobile } = useContext(MenuContext);
    const location   = useLocation();

    useEffect(() => {
        setLocation(location)
    }, [location])

    return (
        <>
            <Helmet title={props.pageTitle} />

            <section className={`container ${props.className ? props.className : ''}`}>
                { mobile ? <MenuMobile /> : <Menu /> }

                <main className={menuOpen ? 'mnOp' : ''}>
                    { props.children }

                    <Footer />

                    {
                        mobile && <div className={`mnOverlay${menuOpen ? ' open' : ''}`}>
                            <Links />
                        </div>
                    }
                </main>
            </section>
        </>
    );
}

export default Site;