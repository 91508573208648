import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import './styles.css';

// Components
import Site from '~/components/System/Layout';

// Images
import aboutDna from '~/assets/images/about/dna.png';
import icon001 from '~/assets/images/about/icon-001.png';
import icon002 from '~/assets/images/about/icon-002.png';
import icon003 from '~/assets/images/about/icon-003.png';
import icon004 from '~/assets/images/about/icon-004.png';
import partner001 from '~/assets/images/about/partners-christian.png';
import partner002 from '~/assets/images/about/partners-ricardo-behar.png';
import advisor001 from '~/assets/images/about/advisor-britaldo.png';
import advisor002 from '~/assets/images/about/advisor-ricardo-asse.png';

const About: React.FC<any> = () => {
    return <Site className='about' title='Sobre Nós - Energizou' pageTitle='Sobre Nós - Energizou'>
        <div className="purpose">
            <div className="content">
                <div className="title">
                    Transformando o mercado de energia para empresas
                </div>

                <div className="text">
                    Nascemos com o propósito de dar liberdade para o consumidor, facilitando o seu acesso ao Mercado Livre de Energia com segurança, flexibilidade, tecnologia e redução de custos.
                </div>
            </div>
        </div>

        <div className="dna content">
            <div className="texts">
                <h2>Nosso DNA</h2>

                <p>Além de transformar a forma de você comprar energia para sua empresa, somos uma comercializadora digital do segmento. Acreditamos em um mundo de possibilidades e escolhas.</p>

                <ul>
                    <li>
                        <img src={icon001} alt="Icone Autonomia" />
                        <div><b>Autonomia</b> - para você escolher o que é melhor para a sua empresa.</div>
                    </li>
                    <li>
                        <img src={icon002} alt="Icone Ambição" />
                        <div><b>Ambição</b> - queremos continuar transformando o jeito de consumir energia.</div>
                    </li>
                    <li>
                        <img src={icon003} alt="Icone Criatividade" />
                        <div><b>Criatividade</b> - buscamos a inovação.</div>
                    </li>
                    <li>
                        <img src={icon004} alt="Icone Autoestima" />
                        <div><b>Ousadia</b> - visão de um mercado em crescimento.</div>
                    </li>
                </ul>
            </div>

            <div className="image">
                <img src={aboutDna} alt="Imagem DNA"/>
            </div>
        </div>

        <div className="team">
            <div className="content">
                <h2>Nossa Equipe</h2>
                <h4>Prezamos por relações transparentes</h4>


                <h3>Sócios</h3>
                <div className="partners">
                    <div className="partner1">
                        <div className="photo">
                            <img src={partner001} alt="Christian Cunha" />
                        </div>

                        <div className="name">
                            Christian Vasconcellos da Cunha
                        </div>

                        <ul className="infos">
                            <li>Foi diretor executivo da JHSF S.A., empresa de capital aberto do ramo imobiliário.</li>
                            <li>Na JHSF, criou a Sustenta Comercializadora de Energia em 2011, e foi seu diretor estatutário e diretor técnico até 2016.</li>
                            <li>Foi gestor de um Family Office brasileiro em Miami entre 2004 e 2008 e executivo da Enron em 2000 e 2001, época da criação do Mercado Livre de Energia.</li>
                        </ul>

                        <div className="links">
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/christian-v-da-cunha-2b342/" className="lki">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>

                    <div className="partner2">
                        <div className="photo">
                            <img src={partner002} alt="Ricardo Behar" />
                        </div>

                        <div className="name">
                            Ricardo Jaime Behar
                        </div>

                        <ul className="infos">
                            <li>Foi Managing Director do Bradesco BBI e esteve envolvido em operações de equity, renda fixa e M&A. No ramo de Investiment Banking desde 1996, com passagens em Morgan Stanley, Salomon Smith Barney, DLJ e Banco de Investimento Garantia.</li>
                            <li>Cobertura de clientes e originação em indústrias de Imobiliário, infraestrutura, cimentos e conglomerados.</li>
                            <li>Atuou em outros segmentos, como papel e celulose, metais e mineração, oil field services e química.</li>
                        </ul>

                        <div className="links">
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/ricardo-jaime-behar-9601b9122/" className="lki">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>
                </div>

                <h3>Conselheiros</h3>
                <div className="advisors">
                    <div className="advisor1">
                        <div className="photo">
                            <img src={advisor001} alt="Britaldo Soares" />
                        </div>

                        <div className="name">
                            Britaldo Soares
                        </div>

                        <ul className="infos">
                            <li>Iniciou sua carreira no Citibank/Citigroup. Foi vice-presidente de Finanças da Enron América do Sul e Prisma Energy, holding da distribuidora de energia Elektro.</li>
                            <li>Em 2005, assumiu a vice-presidência de Finanças e Relações com Investidores das empresas do Grupo AES (AES Eletropaulo, AES Tietê, AES Sul, entre outras).</li>
                            <li>Em 2007 assumiu a presidência do Grupo AES no Brasil, onde atuou por 12 anos.</li>
                            <li>Hoje serve como Conselheiro em diversas empresas de destaque no Setor, como Enel Distribuidora e Atiaia Energia (geração), e Sterlite Power.</li>
                        </ul>

                        <div className="links">
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/britaldo-soares-b876111a/" className="lki">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>

                    <div className="advisor2">
                        <div className="photo">
                            <img src={advisor002} alt="Ricardo Asse" />
                        </div>

                        <div className="name">
                            Ricardo Asse
                        </div>

                        <ul className="infos">
                            <li>Carreira em tecnologia, M&A, consultoria de estratégia, Private Equity e Venture Capital em São Francisco, São Paulo e Nova York.</li>
                            <li>Conselheiro da BRASSCOM, BRAIN, ANBIMA, Anjos do Brasil, Startup Brasil, Softex, Casa do Saber e Comitê de Tecnologia da Amcham.</li>
                            <li>É Founding Partner da Centria Partners, uma boutique financeira do setor de tecnologia</li>
                        </ul>

                        <div className="links">
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/ricardo-asse-208322/" className="lki">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="cta">
                    <h3 className='opps'>Veja as oportunidades disponíveis!</h3>
                    <a target='_blank' className={'page-linkedin'} rel="noreferrer" href="https://br.linkedin.com/company/energizou">Saiba mais</a>
                </div>
            </div>
        </div>
    </Site>
}

export default About;