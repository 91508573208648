import React from 'react';
import { Link } from 'react-router-dom';
import { BiChevronRight } from 'react-icons/bi';
import { SlScreenSmartphone } from "react-icons/sl";
import { BsLightningCharge } from "react-icons/bs";
import { FaRegEye } from "react-icons/fa6";
import './styles.css';

// Components
import Site from '~/components/System/Layout';
import ButtonPrimary from '~/components/Buttons/ButtonPrimary';
import ButtonSecundary from '~/components/Buttons/ButtonSecundary';
import BoxCases from './Components/boxIcon';
import Carrousel from '../Energy/Components/Carousel';
import ItemTimeline from './Components/itemTimeline';

//Images
import { factory, building, university, billExample, homes, example, testimonial001, testimonial002, testimonial003, icon1, icon2, icon3, icon4, icon5 } from './images'

const Energy: React.FC<any> = () => {
    return <Site className='energy' title='Energia - Energizou' pageTitle='Energia - Energizou'>
        <div className="call-to-economy">
            <div className="content">
                <header>
                    <p className='before-title'>Economize</p>
                    <h1>Reduza o Custo de Energia da sua Empresa com o Mercado Livre de Energia</h1>
                </header>

                <body>
                    <p>Descubra como sua empresa pode economizar, escolhendo o fornecedor de energia e reduzindo no custo por cada unidade de energia consumida.</p>
                    <div className="buttons">
                        <ButtonSecundary className={'page-superapp'} text={'Simular economia'} link={'https://app.energizou.com.br/login'} external={true}/>
                        <ButtonPrimary   text={'Entrar em contato'} className={'page-contact'} link={'/contato'}/>
                    </div>
                </body>
            </div>
        </div>

        <div className="cases-economy ">
            <div className="content">
                <header>
                    <p className='before-title'>Casos de uso</p>
                    <h1>Exemplos de economia</h1>
                </header>

                <body>
                    <p>Veja como alguns de nossos clientes economizaram ao nos contratar.</p>
                    <div className="boxes">
                        <BoxCases
                            title={'Indústria'}
                            label={'Uma indústria no ABC Paulista que gastava R$80.000 todo mês com a sua conta de energia. Já no seu primeiro ano, economizou R$ 230.576,40.'}
                            image={factory}
                            alt={'Pequena indústria'}
                            link={'https://energizouhelp.zendesk.com/hc/pt-br/articles/9651381994381-Case-do-cliente-MEGH-Ind%C3%BAstria-e-Com%C3%A9rcio'}
                        />

                        <BoxCases
                            title={'Prédio comercial'}
                            label={'Prédios comerciais tipicamente possuem uma conta de energia elevada, podendo chegar à R$100.000. Para esse público, a Energizou oferece um contrato do Mercado Livre de Energia simples e sem risco, em que o desconto oferecido é garantido.'}
                            image={building}
                            alt={'Prédio comercial'}
                            link={'https://energizouhelp.zendesk.com/hc/pt-br/articles/9651286110605-Case-do-cliente-Hersil-Condom%C3%ADnio-Millenium'}
                        />

                        <BoxCases
                            title={'Universidade'}
                            label={'Universidades que buscam soluções de redução de despesa podem migrar para o Mercado Livre de Energia. Em um mês com altas bandeiras tarifárias, este cliente economizou mais de 41% em um mês.'}
                            image={university}
                            alt={'Universidade'}
                            link={'https://energizouhelp.zendesk.com/hc/pt-br/articles/9651436487693-Case-do-cliente-UNISA'}
                        />
                    </div>
                </body>
            </div>
        </div>

        <div className="contact">
            <div className="content">
                <div className='left'>
                    <p className='before-title'>Economia</p>
                    <h2>Mude para o Mercado Livre de energia e economize</h2>
                    <ButtonPrimary text={'Entre em contato'} className={'page-contact'} light={true} link={'/contato'}/>
                </div>

                <div className='timeline'>
                    <ItemTimeline
                        icon  = {icon1}
                        title = {'Assinatura do contrato'}
                        label = {'Assine o contrato de Compra e Venda de energia junto à Energizou para iniciar o processo de migração para o Mercado Livre de Energia.'}
                    />

                    <ItemTimeline
                        icon  = {icon2}
                        title = {'Aviso à distribuidora'}
                        label = {'Coordenamos todos os processos administrativos junto à concessionária local e a Câmara de Comércio de Energia Elétrica (CCEE), sem qualquer custo adicional para você.'}
                    />

                    <ItemTimeline
                        icon  = {icon3}
                        title = {'Adequação do relógio medidor'}
                        label = {'De acordo com as normas da Agência Nacional de Energia Elétrica (ANEEL), é necessário realizar a adequação técnica da sua cabine de medição antes de mudar para o Mercado Livre de Energia. Esse serviço é realizado por um eletricista e, tipicamente, custa, em média, R$ 5.000.'}
                    />

                    <ItemTimeline
                        icon  = {icon4}
                        title = {'Conclusão da migração'}
                        label = {'Após todas as etapas técnicas e administrativas, a migração é concluída. Você passa a ser oficialmente nosso cliente e começa a economizar com o Mercado Livre de Energia.'}
                    />

                    <ItemTimeline
                        icon  = {icon5}
                        title = {'Onboarding'}
                        label = {'Finalmente, oferecemos um treinamento completo em nossa Sistema web de BI. Isso ajuda você a entender como monitorar seus gastos com energia e identificar oportunidades de economia.'}
                        last  = {true}
                    />
                </div>
            </div>
        </div>

        <div className="carousel-content">
            <div className="content">
                <h2>Funcionalidades do sistema</h2>
                <p>Explore previsões de custo, controle proativo de consumo, relatórios de economia e gestão simplificada de faturas. Maximize sua eficiência energética.</p>
                <Carrousel/>
            </div>
        </div>

        <div className="consulting">
            <div className="content">
                <div>
                    <h2>Economize nas contas de energia hoje</h2>
                    <p>Obtenha uma consulta gratuita ou experimente a nossa aplicação web para analisar instantaneamente a sua fatura de energia e encontrar oportunidades de economia.</p>
                    <div className="buttons">
                        <ButtonPrimary text={'Consultar'} light={true} link={'/sistema'}/>
                    </div>
                </div>

                <img src={example} alt='Diferença da conta de luz com a enregizou'/>
            </div>
        </div>

        <div className="subscribe">
            <div className="content">
                <img src={billExample} alt='Ilustração de uma fatura'/>

                <div>
                    <h2>Suas contas mais acessíveis e transparentes</h2>
                    <p>Com o Energizou, as empresas podem desfrutar de economias potenciais de 15 a 30% inferiores aos seus custos médios mensais de eletricidade. Nossas soluções inovadoras e experiência no mercado aberto de energia nos permitem ajudá-lo a reduzir seus gastos com energia e aumentar seus resultados financeiros.</p>
                    <p><FaRegEye/> Preços transparentes, sem taxas ocultas ou surpresas</p>
                    <p><BsLightningCharge/> Planos de energia personalizados adaptados às necessidades do seu negócio</p>
                    <p> <SlScreenSmartphone/> Aplicativo web fácil de usar para análise automática da conta de energia</p>

                    <div className="buttons">
                        <ButtonPrimary text={'Inscreva-se'} className={'page-superapp'} light={true} link={'https://app.energizou.com.br/login'} external={true}/>
                        <Link to='/sistema' className='page-system'>
                            <p>Saiba mais </p>
                            <BiChevronRight />
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="discover">
            <div className="content">
                <div>
                    <h2>Descubra como a Energizou pode ajudar sua empresa a economizar na conta de luz</h2>
                    <p>Com a Energizou, as empresas obtiveram poupanças significativas nas suas contas de eletricidade. Nossas soluções inovadoras e expertise no mercado de energia ajudaram inúmeras empresas a reduzir seus custos mensais com eletricidade.</p>
                    <h2 className='percentage'>35%</h2>
                    <p>Economia média alcançada pelas empresas que utilizam os serviços da Energizou</p>
                </div>

                <img src={homes} alt='Ilustração de luzes acesas'/>

            </div>
        </div>

        <div className="our-clients">
            <div className="content">
                <h2>O que o nossos clientes dizem sobre</h2>
                <p>Ouça o que nossos clientes satisfeitos têm a dizer</p>

                <div className="testimonials">
                    <div className="client">
                        <div className="header">
                            <div className="photo">
                                <img src={testimonial001} alt="Depoimento João Exemplo da Silva" />
                            </div>

                            <div className="infos">
                                <div className="name">João Exemplo da Silva</div>
                                <div className="office">Gerente de Projetos e Manut. de Indústria</div>
                            </div>
                        </div>

                        <p>“O aplicativo web Energizou nos ajudou a perceber onde eram as melhores oportunidades para economizar na conta.”</p>
                    </div>

                    <div className="client">
                        <div className="header">
                            <div className="photo">
                                <img src={testimonial002} alt="Depoimento Maria Exemplo dos Santos" />
                            </div>

                            <div className="infos">
                                <div className="name">Maria Exemplo dos Santos</div>
                                <div className="office">Gerente de Facilities de uma Universidade</div>
                            </div>
                        </div>

                        <p>“Eu não tinha conhecimento da Energia Reativa e muito menos que estávamos desperdiçando dinheiro com isso”</p>
                    </div>

                    <div className="client">
                        <div className="header">
                            <div className="photo">
                                <img src={testimonial003} alt="Depoimento Marcelo Exemplo Carelli" />
                            </div>

                            <div className="infos">
                                <div className="name">Marcelo Exemplo Carelli</div>
                                <div className="office">Gerente Administrativo e Financeiro</div>
                            </div>
                        </div>

                        <p>“Uso a Sistema apenas para acompanhar a evolução dos nossos gastos mensais e acho a visão em gráfico muito útil”</p>
                    </div>
                </div>
            </div>
        </div>
    </Site>
}

export default Energy;