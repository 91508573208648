import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import './styles.css';

// Components
import Site from '~/components/System/Layout';

// Images
import header from '~/assets/images/system/header.png';
import lamp from '~/assets/images/system/lamp.png';
import iconOpps001 from '~/assets/images/system/icon-opps-001.png';
import iconOpps002 from '~/assets/images/system/icon-opps-002.png';
import iconOpps003 from '~/assets/images/system/icon-opps-003.png';
import iconPlates from '~/assets/images/system/icon_plates.png';
import iconMoney from '~/assets/images/system/icon-money.png';
import iconSpeed from '~/assets/images/system/icon-high-speed.png';
import iconRays from '~/assets/images/system/icon-rays.png';
import iconSignal from '~/assets/images/system/icon-low-signal.png';
import iconPercent from '~/assets/images/system/icon-percent.png';
import testimonial001 from '~/assets/images/system/testimonials-001.png';
import testimonial002 from '~/assets/images/system/testimonials-002.png';
import testimonial003 from '~/assets/images/system/testimonials-003.png';

const System: React.FC<any> = () => {
    return <Site className='system' title='Sistema - Energizou' pageTitle='Sistema - Energizou'>
        <div className="header">
            <div className="content">
                <div className="texts">
                    <h1>Analise sua conta de energia e economize</h1>
                    <p>Nosso Sistema de gestão <b>gratuito</b> analisa automaticamente sua conta de energia e identifica oportunidades de economia.</p>

                    <div className="btns">
                        <a target="_blank" rel="noreferrer" href="https://app.energizou.com.br" className="analysis page-superapp">
                            Análise Grátis da Conta
                        </a>

                        <Link to='/energia' className="buy page-energy">
                            Comprar Energia
                        </Link>
                    </div>
                </div>

                <div className="image">
                    <img src={header} alt="Gráficos do Sistema" />
                </div>
            </div>
        </div>

        <div className="system">
            <div className="content">
                <div className="texts">
                    <h4>Sistema de Análise Grátis!</h4>
                    <h2>Análise automática e oportunidades de economia</h2>

                    <p>O mesmo Sistema de Gestão que oferecemos aos nossos clientes, também disponibilizamos de forma <b>gratuita</b> para que você possa fazer a análise automática da conta de energia da sua <b>empresa</b>. O sistema faz recomendações personalizadas, identificando oportunidades para a redução de custos com energia.</p>

                    <ul>
                        <li>
                            <img src={iconOpps001} alt="Icone Recomendações" />
                            Quanto mais contas, melhores recomendações
                        </li>
                        <li>
                            <img src={iconOpps002} alt="Icone Implementação" />
                            Explicação sobre como implementar cada oportunidade
                        </li>
                        <li>
                            <img src={iconOpps003} alt="Icone Gratuíto" />
                            É Grátis! Mesmo para quem não é cliente Energizou
                        </li>
                    </ul>

                    <a target="_blank" rel="noreferrer" href="https://app.energizou.com.br" className="register page-superapp">
                        Faça seu Cadastro e Use Grátis
                    </a>
                </div>

                <div className="image">
                    <img src={lamp} alt="Imagem DNA"/>
                </div>
            </div>
        </div>

        <div className="opps content">
            <h2>Oportunidades frequentes para empresas</h2>

            <div className="lines">
                <div className="opp">
                    <div className="image">
                        <img src={iconPlates} alt="Icone Modalidade Tarifária" />
                    </div>

                    <h4>Modalidade Tarifária</h4>
                    <p>O consumidor de energia de Alta Tensão pode optar pelo enquadramento nas modalidades Azul ou Verde de cobrança com a sua distribuidora.</p>

                    <a target="_blank" rel="noreferrer" href="https://app.energizou.com.br/criar-conta" className="link page-superapp">
                        Faça o cadastro e tenha a análise grátis <FaChevronRight />
                    </a>
                </div>

                <div className="opp">
                    <div className="image">
                        <img src={iconMoney} alt="Icone Multa por Atraso" />
                    </div>

                    <h4>Multa por atraso de pagamento</h4>
                    <p>Alguns consumidores fazem o pagamento na data errada do vencimento da cobrança, o que pode ocasionar em custos adicionais não necessários.</p>

                    <a target="_blank" rel="noreferrer" href="https://app.energizou.com.br/criar-conta" className="link page-superapp">
                        Faça o cadastro e tenha a análise grátis <FaChevronRight />
                    </a>
                </div>

                <div className="opp">
                    <div className="image">
                        <img src={iconSpeed} alt="Icone Ultrapassagem de Demanda" />
                    </div>

                    <h4>Multa por Demanda Excedida</h4>
                    <p>Ultrapassagem de demanda é uma cobrança adicional identificada na fatura de energia que ocorre quando a demanda registrada é superior à contratada.</p>

                    <a target="_blank" rel="noreferrer" href="https://app.energizou.com.br/criar-conta" className="link page-superapp">
                        Faça o cadastro e tenha a análise grátis <FaChevronRight />
                    </a>
                </div>
            </div>

            <div className="lines">
                <div className="opp">
                    <div className="image">
                        <img src={iconRays} alt="Icone Energia Reativa" />
                    </div>

                    <h4>Multa por Energia Reativa</h4>
                    <p>A multa por energia reativa ocorre quando o consumidor gera energia reativa no seu ponto de consumo. Esse valor adicional pode ser eliminado com algumas ações.</p>

                    <a target="_blank" rel="noreferrer" href="https://app.energizou.com.br/criar-conta" className="link page-superapp">
                        Faça o cadastro e tenha a análise grátis <FaChevronRight />
                    </a>
                </div>

                <div className="opp">
                    <div className="image">
                        <img src={iconSignal} alt="Icone Demanda não Utilizada" />
                    </div>

                    <h4>Custo com Demanda não Utilizada</h4>
                    <p>A demanda não utilizada é identificada quando a demanda faturada é inferior à demanda contratada. Ou seja, o consumidor está pagando por algo que nãa está utilizando.</p>

                    <a target="_blank" rel="noreferrer" href="https://app.energizou.com.br/criar-conta" className="link page-superapp">
                        Faça o cadastro e tenha a análise grátis <FaChevronRight />
                    </a>
                </div>

                <div className="opp">
                    <div className="image">
                        <img src={iconPercent} alt="Icone Mercado Livre de Energia" />
                    </div>

                    <h4>Mercado Livre de Energia</h4>
                    <p>O Mercado Livre de Energia é uma outra modalidade do setor elétrico que permite que empresas possam contratar a sua energia de forma livre e economizar.</p>

                    <Link to='/energia' className="btn page-energy">
                        Saiba mais!
                    </Link>
                </div>
            </div>
        </div>

        <div className="benefits">
            <div className="content">
                <h2>Quem pode se beneficiar do  Sistema de Gestão e Análise</h2>
                <h4>Veja exemplos de como o Sistema da Energizou pode te ajudar...</h4>

                <div className="testimonials">
                    <div className="client">
                        <div className="header">
                            <div className="photo">
                                <img src={testimonial001} alt="Depoimento João Exemplo da Silva" />
                            </div>
                            <div className="infos">
                                <div className="name">João Exemplo da Silva</div>
                                <div className="office">Gerente de Projetos e Manut. de Indústria</div>
                            </div>
                        </div>

                        <p>“O aplicativo web Energizou nos ajudou a perceber onde eram as melhores oportunidades para economizar na conta.”</p>
                    </div>

                    <div className="client">
                        <div className="header">
                            <div className="photo">
                                <img src={testimonial002} alt="Depoimento Maria Exemplo dos Santos" />
                            </div>
                            <div className="infos">
                                <div className="name">Maria Exemplo dos Santos</div>
                                <div className="office">Gerente de Facilities de uma Universidade</div>
                            </div>
                        </div>

                        <p>“Eu não tinha conhecimento da Energia Reativa e muito menos que estávamos desperdiçando dinheiro com isso”</p>
                    </div>

                    <div className="client">
                        <div className="header">
                            <div className="photo">
                                <img src={testimonial003} alt="Depoimento Marcelo Exemplo Carelli" />
                            </div>
                            <div className="infos">
                                <div className="name">Marcelo Exemplo Carelli</div>
                                <div className="office">Gerente Administrativo e Financeiro</div>
                            </div>
                        </div>

                        <p>“Uso a Sistema apenas para acompanhar a evolução dos nossos gastos mensais e acho a visão em gráfico muito útil”</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="save">
            <div className="content">
                <h2>Economize de até 35% nas Contas de Energia da sua Empresa!</h2>
                <div className="description">A Energizou é uma das maiores comercializadoras de energia do mercado e já ajudamos mais de 200 empresas a economizarem em suas contas de energia. Ajude a sua empresa dando liberdade de escolha para comprar uma energia mais barata e renovável.</div>

                <div className="btns">
                    <Link to='/contato' className="buy page-contact">
                        Compre da Energizou!
                    </Link>

                    <Link to='/energia' className="works page-energy">
                        Como Funciona
                    </Link>
                </div>
            </div>
        </div>
    </Site>
}

export default System;