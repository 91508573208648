import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';

type Props = {
    text     : string,
    link     : string,
    light   ?: boolean,
    icon    ?: any,
    external?: boolean;
}

const ButtonNormal: React.FC<any> = (props:Props) => {
    if (props.external === true) {
        return <a className={`btn-normal`} target='_blank' rel="noreferrer" href={props.link}>
            {props.text}
            {props.icon}
        </a>
    } else {
        return <Link to={props.link} className={`btn-normal`}>
            {props.text}
            {props.icon}
        </Link>
    }
}

export default ButtonNormal;