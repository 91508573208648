import React from 'react';

type itemTimeline = {
    icon : string,
    title: string,
    label: string,
    last?: boolean,
}

function ItemTimeline (props: itemTimeline) {
    return <div className='item'>
        <div className='icons'>
            <img src={props.icon} alt={props.title}/>
            {! props.last && <div className='vl'/>}
        </div>
        <div className='texts'>
            <h6>{props.title}</h6>
            <p>{props.label}</p>
        </div>
    </div>
}

export default ItemTimeline;