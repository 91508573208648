import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuContext } from '~/contexts';
import './stylesLinks.css';

const blog        = 'https://blog.energizou.com.br';
const appLogin    = 'https://app.energizou.com.br';

const Links = () => {
    const location   = useLocation();
    const { mobile } = useContext(MenuContext);

    return (
        <div className={`links${mobile ? ' mbe' : ''}`}>
            <Link className={location.pathname.indexOf('sobre-nos')>=0 ? ' active' : ''} to='/sobre-nos'>
                Sobre
            </Link>

            <Link className={location.pathname.indexOf('energia')>=0 ? ' active' : ''} to='/energia'>
                Energia
            </Link>

            <Link className={location.pathname.indexOf('sistema')>=0 ? ' active' : ''} to='/sistema'>
                Sistema
            </Link>

            <a target='_blank' rel="noreferrer" href={blog}>
                Blog
            </a>

            <Link className={location.pathname.indexOf('contato')>=0 ? ' active' : ''} to='/contato'>
                Contato
            </Link>

            <div className="btns">
                <a className='btn register page-superapp' target='_blank' rel="noreferrer" href={appLogin}>
                    Cadastrar
                </a>
            </div>
        </div>
    );
}

export default Links;
