import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import './styles.css';

const Error404: React.FC = () => {
    return <>
        <Helmet title="Página não encontrada" />

        <main className="pageError">
            <h1>
                <span>4</span>
                <span>0</span>
                <span>4</span>
            </h1>

            <h2>Página não encontrada!</h2>

            <Link to="/" className="btnHome">
                <FiArrowLeft size="18" />
                <span>Voltar a página inicial</span>
            </Link>
        </main>
    </>
}

export default Error404;