import { createContext, ReactNode, useEffect, useState } from "react";

interface MenuContextData {
    toggleMenu: Function;
    setLocation: Function;
    menuOpen: boolean;
    mobile: boolean;
}

interface MenuProviderProps {
    children: ReactNode;
}

export const MenuContext = createContext({} as MenuContextData)

export function MenuProvider ({ children }: MenuProviderProps) {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [mobile, setMobile]     = useState<boolean>(false);
    const [location, setLocation] = useState<any>('');

    function toggleMenu () {
        setMenuOpen(!menuOpen)
    }

    function checkMobile () {
        setMobile(window.innerWidth<992 ? true : false);
    }

    useEffect(() => {
        checkMobile();
        window.addEventListener("resize", checkMobile, false);
    }, [])

    useEffect(() => {
        if (menuOpen) toggleMenu();
      }, [location]);

    return (
        <MenuContext.Provider value={{ toggleMenu, setLocation, menuOpen, mobile }}>
            { children }
        </MenuContext.Provider>
    )
}