import { useContext } from 'react';
import { Link } from 'react-router-dom';
import './stylesMobile.css';

// Images
import logo from '~/assets/images/logo/logo.svg';
import { MenuContext } from '~/contexts';

const Menu = () => {
    const { menuOpen, toggleMenu } = useContext(MenuContext);

    return (
        <>
            <nav className="menuMobile">
                <div className="container">
                    <Link to='/' className="logo">
                        <img src={logo} alt="Energizou"/>
                    </Link>

                    <div className="menu_toggle" onClick={() => { toggleMenu() }}>
                        <div className="one"    style={menuOpen ? {transform: 'rotate(45deg) translate(4px, 5px)'}:{}}></div>
                        <div className="two"    style={menuOpen ? {opacity: '0'}:{}}></div>
                        <div className="three"  style={menuOpen ? {transform: 'rotate(-45deg) translate(5px, -6px)'}:{}}></div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Menu;
