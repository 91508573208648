import $ from 'jquery';

const BannerAnimations = {
    configs: {
        opacity: 800,
        move: 1800
    },
    banner: undefined as any,
    section: undefined as any,
    browserOff: false as boolean,

    init: function (browserOff: boolean) {
        this.banner     = $('body > #root > .container > main > #banner');
        this.section    = this.banner.find('> .animation-container');
        this.browserOff = browserOff;

        // Pre-load
        setTimeout(() => {
            this.banner.find('> .preloader').fadeOut(1000, () => {this.banner.find('> .preloader').remove()});
            this.animation();
            this.lights();
            if (!this.browserOff) this.scrollMove();
        }, 3000);
    },

    scrollMove: function() {
        // console.log($(window).scrollTop())
        // $(document).on('scroll', () => {
        //     let screenX = parseInt($(window).scrollTop()),
        //         screenH = screen.height;

        //     if (screenX < screenH) {
        //         let boatRight  = this.section.find("> .boat_right"),
        //             palm       = this.section.find("> .palm"),
        //             palm2      = this.section.find("> .palms_loop"),
        //             shops1     = this.section.find("> .shops_front"),
        //             shops2     = this.section.find("> .shops_back"),
        //             water      = this.section.find("> .water"),
        //             background = this.section.find("> .background");

        //         boatRight.translate3d({x: (screenX / 4), y: 0, z: 0}, 5000, 'easeInOutQuad');
        //         palm.translate3d({x: -(screenX / 8), y: 0, z: 0}, 5000, 'easeInOutQuad');
        //         palm2.translate3d({x: -(screenX / 8), y: 0, z: 0}, 5000, 'easeInOueaseInOutQuadtSine');
        //         shops1.translate3d({x: (screenX / 10), y: 0, z: 0}, 4000, 'easeInOutQuad');
        //         shops2.translate3d({x: (screenX / 14), y: 0, z: 0}, 4000, 'easeInOutQuad');
        //         water.translate3d({x: (screenX / 18), y: 0, z: 0}, 4000, 'easeInOutQuad');
        //         background.translate3d({x: (screenX / 18), y: 0, z: 0}, 4000, 'easeInOutQuad');
        //     }
        // });
    },

    animation: function() {
        this._palms();
        this._boats();
        this._shopsFront();
        this._shopsBack();
        this._background();
        this._water();
    },

    lights: function() {
        this._shopsFrontWindows();
        this._shopsBackWindows();
        this._backgroundWindows();
    },

    // Parts
    _palms: function() {
        let palms = this.section.find('> .palmsBox');

        // Styles
        palms.css('transform', 'translate3d(0px, 260px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-webkit-transform', 'translate3d(0px, 260px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-ms-transform', 'translate3d(0px, 260px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)');
        palms.css('transform-style', 'preserve-3d').css('-webkit-transform-style', 'preserve-3d');
        palms.css('opacity', 0).css('filter', 'alpha(opacity=0)');

        // Execution
        palms.animate({opacity: 1}, 500);
        palms.translate3d({x: 0, y: 0, z: 0}, 1500, 'easeInOutQuad');
    },

    _boats: function() {
        let boat_left  = this.section.find('> .boat_left'),
            boat_right = this.section.find('.boat_right');

        // Styles
        boat_left.css('transform', 'translate3d(-80px, 220px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-webkit-transform', 'translate3d(-80px, 220px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-ms-transform', 'translate3d(-80px, 220px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)');
        boat_left.css('transform-style', 'preserve-3d').css('-webkit-transform-style', 'preserve-3d');
        boat_left.css('opacity', 0).css('filter', 'alpha(opacity=0)');

        boat_right.css('transform', 'translate3d(80px, 220px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-webkit-transform', 'translate3d(80px, 220px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-ms-transform', 'translate3d(80px, 220px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)');
        boat_right.css('transform-style', 'preserve-3d').css('-webkit-transform-style', 'preserve-3d');
        boat_right.css('opacity', 0).css('filter', 'alpha(opacity=0)');

        // Execution
        boat_left.animate({opacity: 1}, this.configs.opacity);
        boat_left.translate3d({x: 0, y: 0, z: 0}, this.configs.move, 'easeInOutQuad');

        boat_right.animate({opacity: 1}, this.configs.opacity);
        boat_right.translate3d({x: 0, y: 0, z: 0}, this.configs.move, 'easeInOutQuad');
    },

    _shopsFront: function() {
        let shopsFront = this.section.find('> .shops_front');

        // Styles
        shopsFront.css('transform', 'translate3d(0px, 220px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-webkit-transform', 'translate3d(0px, 220px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-ms-transform', 'translate3d(0px, 220px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)');
        shopsFront.css('transform-style', 'preserve-3d').css('-webkit-transform-style', 'preserve-3d');
        shopsFront.css('opacity', 0).css('filter', 'alpha(opacity=0)');

        // Execution
        shopsFront.animate({opacity: 1}, this.configs.opacity);
        shopsFront.translate3d({x: 0, y: 0, z: 0}, this.configs.move, 'easeInOutQuad');
    },

    _shopsBack: function() {
        let shopsBack = this.section.find('> .shops_back');

        // Styles
        shopsBack.css('transform', 'translate3d(0px, 130px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-webkit-transform', 'translate3d(0px, 130px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-ms-transform', 'translate3d(0px, 130px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)');
        shopsBack.css('transform-style', 'preserve-3d').css('-webkit-transform-style', 'preserve-3d');
        shopsBack.css('opacity', 0).css('filter', 'alpha(opacity=0)');

        // Execution
        shopsBack.animate({opacity: 1}, this.configs.opacity);
        shopsBack.translate3d({x: 0, y: 0, z: 0}, this.configs.move, 'easeInOutQuad');
    },

    _background: function() {
        let background = this.section.find('> .background');

        // Styles
        background.css('transform', 'translate3d(0px, 80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-webkit-transform', 'translate3d(0px, 80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-ms-transform', 'translate3d(0px, 80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)');
        background.css('transform-style', 'preserve-3d').css('-webkit-transform-style', 'preserve-3d');
        background.css('opacity', 0).css('filter', 'alpha(opacity=0)');

        // Execution
        background.animate({opacity: 1}, this.configs.opacity);
        background.translate3d({x: 0, y: 0, z: 0}, this.configs.move, 'easeInOutQuad');
    },

    _water: function() {
        let water = this.section.find("> .water");

        // Styles
        water.css('transform', 'translate3d(0px, 80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-webkit-transform', 'translate3d(0px, 80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)').css('-ms-transform', 'translate3d(0px, 80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)');
        water.css('transform-style', 'preserve-3d').css('-webkit-transform-style', 'preserve-3d');
        water.css('opacity', 0).css('filter', 'alpha(opacity=0)');

        // Execution
        water.animate({opacity: 1}, this.configs.opacity);
        water.translate3d({x: 0, y: 0, z: 0}, this.configs.move, 'easeInOutQuad');
    },

    // Lights
    _shopsFrontWindows: function () {
        let shopsFrontWindows1 = this.section.find('> .shops_front > .house_1'),
            shopsFrontWindows2 = this.section.find('> .shops_front > .house_2'),
            shopsFrontWindows3 = this.section.find('> .shops_front > .house_3');

        setTimeout(() => { shopsFrontWindows1.fadeOut(300, () => { shopsFrontWindows1.remove(); }) }, (this.configs.move+300));
        setTimeout(() => { shopsFrontWindows2.fadeOut(300, () => { shopsFrontWindows2.remove(); }) }, (this.configs.move+700));
        setTimeout(() => { shopsFrontWindows3.fadeOut(300, () => { shopsFrontWindows3.remove(); }) }, (this.configs.move+1000));
    },

    _shopsBackWindows: function () {
        let shopsBackWindows1 = this.section.find('> .shops_back > .house_1'),
            shopsBackWindows2 = this.section.find('> .shops_back > .house_2');

        setTimeout(() => { shopsBackWindows1.fadeOut(300, () => { shopsBackWindows1.remove(); }) }, (this.configs.move+1300));
        setTimeout(() => { shopsBackWindows2.fadeOut(300, () => { shopsBackWindows2.remove(); }) }, (this.configs.move+1500));
    },

    _backgroundWindows: function () {
        let backgroundkWindows1 = this.section.find('> .background > .windows_1'),
            backgroundkWindows2 = this.section.find('> .background > .windows_2');

        setTimeout(() => { backgroundkWindows1.fadeOut(300, () => { backgroundkWindows1.remove(); }) }, (this.configs.move+1900));
        setTimeout(() => { backgroundkWindows2.fadeOut(300, () => { backgroundkWindows2.remove(); }) }, (this.configs.move+2200));
    }
};

export default BannerAnimations;

// function hasAttr(el: any, name: string) {
//     return el.attr(name) !== undefined;
// };

;(function($: any) {
    var delay = 0;
    $.fn.translate3d = function(translations: any, speed: number, easing: string, complete: any) {
        var opt = $.speed(speed, easing, complete);
        opt.easing = opt.easing || 'ease';
        translations = $.extend({x: 0, y: 0, z: 0}, translations);

        return this.each(function(this: any) {
            var $this = $(this);

            $this.css({
                transitionDuration: opt.duration + 'ms',
                transitionTimingFunction: opt.easing,
                transform: 'translate3d(' + translations.x + 'px, ' + translations.y + 'px, ' + translations.z + 'px)'
            });

            setTimeout(function() {
                $this.css({
                    transitionDuration: '0s',
                    transitionTimingFunction: 'ease'
                });

                opt.complete();
            }, opt.duration + (delay || 0));
        });
    };
})($);