import React from 'react';
import { Collapse } from 'react-collapse';
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import './styles.css';
interface Props {
    title: string;
    description: string;
    index: number;
    collapseOpen: number;
    setCollapseOpen: Function;
}

const ConditionsBox: React.FC<Props> = ({title, description, index, collapseOpen, setCollapseOpen}: Props) => {
    let isOpened = collapseOpen===index ? true : false;

    return <div
      className="conditionsBox"
      onClick={() => setCollapseOpen(index)}>
         {
          index > 7 ?<div></div> : <hr />
        }
        <div className="headLine">
            <div className="name">
                <div className='titulo'>
                      {title}
                </div>
              <div className='icon'>
                { !isOpened ? <button className="more" >
                  <BiChevronDown size={20}/></button> :
                  <button className="more" onClick={() =>
                setCollapseOpen(50)}><BiChevronUp size={20}/></button>
                }
              </div>
            </div>
        </div>

        <Collapse isOpened={isOpened}>
            <div className="description">{description}</div>
        </Collapse>
        {
          index === 7 ? <hr />:  <div></div>
        }
    </div>;
}

export default ConditionsBox;